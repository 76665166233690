import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddBook from "./AddBook";
import "./Main.css";

export default function Main() {
  const [books, setBooks] = useState([
    {
      id: 4,
      name: "The Line of Beauty",
      author: "Alan Hollinghurst",
      publish: "1985",
    },
    {
      id: 3,
      name: "Shuggie Bain",
      author: "Douglas Stuart",
      publish: "1980",
    },
    { id: 2, name: "Luster", author: "Raven Leilani", publish: "2020" },
    { id: 1, name: "Room", author: "Emma Donoghue", publish: "2008" },
  ]);
  const [bookName, setBookName] = useState("");
  const [author, setAuthor] = useState("");
  const [publish, setPublish] = useState("");
  const [formValid, setFormValid] = useState(true);

  function formCleaner() {
    setBookName("");
    setAuthor("");
    setPublish("");
  }

  function addBook() {
    if ((bookName, author, publish.length === 4)) {
      let newBook = {
        id: books.length + 1,
        name: bookName,
        author,
        publish,
      };

      setBooks([{ ...newBook }, ...books]);
      setFormValid(true);
      formCleaner();
    } else {
      setFormValid(false);
    }
  }

  function deleteHandler(id) {
    console.log(id);
    setBooks((prevBooks) => {
      let deleteBook = prevBooks.filter((book) => {
        return book.id !== id;
      });

      return deleteBook;
    });
  }

  return (
    <main>
      <section className="book-form-parent">
        <form
          onSubmit={(event) => event.preventDefault()}
          className="book-form"
        >
          <input
            type="text"
            placeholder="Book name"
            className="book-form-inputs"
            value={bookName}
            onChange={(event) => setBookName(event.target.value)}
          />
          <input
            type="text"
            placeholder="Author"
            className="book-form-inputs"
            value={author}
            onChange={(event) => setAuthor(event.target.value)}
          />
          <input
            type="number"
            placeholder="Publish Year"
            className="book-form-inputs"
            value={publish}
            onChange={(event) => setPublish(event.target.value)}
          />
          <div className="book-form-submit-parent">
            <button
              type="submit"
              className="book-form-submit"
              onClick={addBook}
            >
              <FontAwesomeIcon icon="fa-regular fa-square-plus" />
              Add to books
            </button>
          </div>
          {!formValid && <span className="form-error">Form Is Not Valid</span>}
        </form>
      </section>
      <section className="book-list-parent">
        <table>
          <thead>
            <tr>
              <th className="book-table-head book-id-head">ID</th>
              <th className="book-table-head book-name-head">Name</th>
              <th className="book-table-head book-author-head">Author</th>
              <th className="book-table-head book-publish-head">Publish</th>
            </tr>
          </thead>
          <tbody>
            {books.map((book) => {
              return (
                <AddBook {...book} deleteHandler={(id) => deleteHandler(id)} />
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={4}>Click on any of the books to delete</td>
            </tr>
          </tfoot>
        </table>
      </section>
    </main>
  );
}
