import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Header.css";
export default function Header() {
  return (
    <header>
      <h1>
        <FontAwesomeIcon icon="fa-solid fa-book" />
        <span>React </span>
        Book List :)
      </h1>
    </header>
  );
}
