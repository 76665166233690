import React from "react";

export default function AddBook(props) {
  return (
    <>
      <tr key={props.id} onClick={() => props.deleteHandler(props.id)}>
        <td className="book-id-value">{props.id}</td>
        <td>{props.name}</td>
        <td>{props.author}</td>
        <td>{props.publish}</td>
      </tr>
    </>
  );
}
